import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { AppProvider as ShopifyProvider, Frame } from '@shopify/polaris';
import { PlatformType } from '../../../Constant';
import { PLATFORM } from '../../../config';
import { ThemeProvider, theme } from '@reconvert/react-ui-component';

const AppProvider = ({ children }) => {
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <ChakraProvider theme={theme}>
                    <ThemeProvider>
                        {children}
                    </ThemeProvider>
                </ChakraProvider>
                :
                <ShopifyProvider>
                    <Frame>
                        {children}
                    </Frame>
                </ShopifyProvider>
            }
        </>
    )
};

export default AppProvider;
