import React, { useContext } from 'react';
import { t } from 'i18next';
import { PageData } from '../../../../../ContextAPI/Context';
import { PlatformType, ThreeSectionLayout } from '../../../../../Constant';
import { PLATFORM } from '../../../../../config';
import { Typography } from '@reconvert/react-ui-component';

const RightBarSectionLinks = ({ rightBarComponentType, widgetId }) => {
  const { rightBarLinks, isRequiredDataFilled, setSelectedLink } = useContext(PageData);

  const handleLinkClick = (obj) => {
    if (isRequiredDataFilled()) {
      setSelectedLink(obj);
    }
  }

  return (
    <>
      { rightBarLinks && rightBarLinks.length > 0 &&
        <div className='links-container'>
          {!ThreeSectionLayout.includes(rightBarComponentType) && <div className='link-divider' />}
          <div className={PLATFORM === PlatformType.Wix ? 'wix-link-title' : 'link-title'}>{!widgetId ? t(`edit_widgets_in_this_widget`) : t(`edit_blocks_in_this_widget`)}</div>
          {rightBarLinks.map((item, index) => {
            return <Typography key={index} className={PLATFORM === PlatformType.Wix ? 'wix-link-content' : 'link-content'} onClick={() => handleLinkClick(item)}>{item.name}</Typography>
          })}
        </div>
      }
    </>
  )
}

export default RightBarSectionLinks;