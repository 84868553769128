import { useCallback, useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';
import { PageData } from '../../../../../ContextAPI/Context';
import { CDN_BASE_URL } from '../../../../../config';
import { Ids, getConditionalBlocks, getBlocksFromWidgets, getTargetString, getTargetedValue } from '../../../../../Utils/Common';
import { BlockLocation, TargetConditionTypes, UpsellBlocks, WidgetType } from '../../../../../Constant';
import { IconSource } from '../../../../../Shared/Components/Icon';
import { Popover } from '../../../../../Shared/Components';

/*
  add block button to add blocks in product upsell
*/
const AddBlock = (props) => {
  const { isOpen, addBlock, openId, id, widgetId, parentWidgetId, target, widgetType, parentWidgetType, WidgetLayoutId,uniqueObjects } = props;
  const { unifiedEditor, configSetting, updateData, isRequiredDataFilled, latestConfigSetting, updateRightBarLinks } = useContext(PageData);
  const [uniqueAddBlock, setUniqueAddBlock] = useState([]);
  const [defaultBlocks, setDefaultBlocks] = useState([]);
  const { setOpenId } = openId || {};

  const getAddingBlocks = useCallback(() => {
    let checking = [];
      const blocksList = getBlocksFromWidgets(unifiedEditor.defaultSections, true);
      setDefaultBlocks(blocksList);

      const editorBlocks =  getBlocksFromWidgets(unifiedEditor.widgets, true);
      // check blocks condition according to the parent widget type.
      let blocks = getConditionalBlocks(blocksList, editorBlocks, parentWidgetType, TargetConditionTypes.EditorSchema);

      const selectedWidget = getTargetedValue(latestConfigSetting, 'widgets.[id:{TOP_WIDGET_ID}]', '', parentWidgetId);
      if (parentWidgetType === WidgetType.Bundle && !selectedWidget?.allowSeparateQuantitySelection) {
      blocks = blocks.filter((item) => item.type !== UpsellBlocks.QuantitySelector)
    }

    const WidgetLayout = getTargetedValue(latestConfigSetting, 'widgets.[id:{TOP_WIDGET_ID}]', '', WidgetLayoutId);
    const isSmallLayout = WidgetLayout?.upsellInfo?.layout === "SMALL_LAYOUT_ONE" || WidgetLayout?.upsellInfo?.layout === "SMALL_LAYOUT_TWO";

    blocks.forEach((block, index) => {
      const blockExists = addBlock.find(blockData => blockData.blockName === block.blockName);
      if (!blockExists || (blockExists && blockExists.count < blockExists.maxCount && !isSmallLayout)) {
        checking.push({ ...block, index: index, label: block.blockName, value: blockExists && blockExists.id ? blockExists.id : Ids(), media: <img src={CDN_BASE_URL + `assets/${block.icon}`} alt={block.icon} /> });
      }
    })

    setUniqueAddBlock(checking);
  }, [addBlock, parentWidgetType]);

  useEffect(() => {
    setOpenId((prevOpenId) => ({
      ...prevOpenId,
      id: !id,
    }));

    getAddingBlocks();
  }, [unifiedEditor, addBlock, id, getAddingBlocks, setOpenId]);

  const handleChange = (ids) => {
    if (isRequiredDataFilled()) {
      let latestSetting = cloneDeep(configSetting[configSetting.length - 1]);

      const selectedBlock = uniqueAddBlock.find((item) => item?.value === ids);
      const id = Ids();

      // get blocks default value
      let value = defaultBlocks.find(item => item.type === selectedBlock.type);

      let addBlockObject = getTargetedValue(latestSetting, `${target}.blocks`, widgetId, parentWidgetId);
      if (selectedBlock?.syncSettings) {
        let addBlockClone = cloneDeep(addBlockObject);
        if (parentWidgetId) {
          const parentTarget = getTargetString(target, 2, 2);
          const widget = getTargetedValue(latestSetting, parentTarget, widgetId, parentWidgetId);
          addBlockClone = getBlocksFromWidgets(widget.widgets);
        }

        const isExist = addBlockClone.some(obj => obj.type === selectedBlock.type);
        if (isExist) {
          value = addBlockClone.find(item => item.type === selectedBlock.type);
        }
      }

      if (selectedBlock && selectedBlock?.isFixed) {
        addBlockObject.splice(selectedBlock.index, 0, { ...value, id: id });
      } else {
        addBlockObject.push({ ...value, id: id, location: widgetType === WidgetType.Upsell ? value?.location : BlockLocation[widgetType] })
      }
      // used to dynamically update the right bar links
      updateRightBarLinks(latestSetting,widgetType,parentWidgetId,widgetId);
      updateData(latestSetting);

    }
  };

  return (
    <>
      {uniqueAddBlock?.length > 0 ? (
        <ul>
          <li className={`add_block sub-menu ${!isOpen ? 'collapsed' : ''}`}>
            <Popover
              options={uniqueAddBlock || []}
              onClick={handleChange}
              iconSource={IconSource.Plus}
              title={t('add_block')}
              titleClassName={'add-section-title-link'}
            />
          </li>
        </ul>
      ) : (
        <div className={`disabled-add-button`}>
          <img src={`assets/plus_disabled.svg`} alt={`Add Block`} />
          {t('add_block')} ({uniqueObjects?.length}/ {uniqueObjects?.length})
        </div>
      )}
    </>
  );
};

export default AddBlock;
