import React from 'react';

const ProductRecommendation = () => {
  return (
    <svg
      width="148"
      height="56"
      viewBox="0 0 148 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.49805"
        y="0.5"
        width="46"
        height="33"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect
        x="2.5"
        y="46.5"
        width="45"
        height="9"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect x="1.99805" y="36" width="45" height="3" rx="1.5" fill="#DADEE6" />
      <rect
        x="1.99805"
        y="41"
        width="34.7091"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <rect
        x="51.498"
        y="0.5"
        width="46"
        height="33"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect
        x="51.5"
        y="46.5"
        width="45"
        height="9"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect x="50.998" y="36" width="45" height="3" rx="1.5" fill="#DADEE6" />
      <rect
        x="50.998"
        y="41"
        width="34.7091"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <rect
        x="100.502"
        y="0.5"
        width="45"
        height="33"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect
        x="100.5"
        y="46.5"
        width="45"
        height="9"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect x="99.998" y="36" width="45" height="3" rx="1.5" fill="#DADEE6" />
      <rect
        x="99.998"
        y="41"
        width="34.7091"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
    </svg>
  );
};

export default ProductRecommendation;
