import React, { useContext, useEffect, useState } from 'react'
import styles from './RenderModalContent.module.css'
import { InputGroup, InputRightElement, NumberInput, NumberInputField, Radio, RadioGroup, Stack, Button } from '@chakra-ui/react';
import { Tooltip as ChakraTooltip } from '@chakra-ui/react'
import { LAUNCH_DARKLY_ENABLE_WIX_PRODUCT_OPTIONS_TO_DISPLAY, ONBOARDING_VIDEO_URL, PLATFORM } from '../../../../config';
import ReactPlayer from 'react-player'
import { t } from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { PageData } from '../../../../ContextAPI/Context';
import { shouldOpenEditorFromUrl } from '../../../../Utils/Common';
import { PlatformType } from '../../../../Constant';

const RenderModalContent = (props) => {
    return <VersionB {...props} />
}

const Title = ({ step, title, tooltipText }) => {
    return (
        <div>
            <div className={styles.label}>{step}</div>
            <div className={styles.title_container}>
                <label className={styles.title_wrap}>{title}</label>
                <ChakraTooltip hasArrow label={tooltipText}>
                    <span className="ue_infolabel">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#6A7381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10 15C10.5178 15 10.9375 14.5803 10.9375 14.0625C10.9375 13.5447 10.5178 13.125 10 13.125C9.48223 13.125 9.0625 13.5447 9.0625 14.0625C9.0625 14.5803 9.48223 15 10 15Z" fill="#6A7381" />
                            <path d="M10 11.25V10.625C10.4326 10.625 10.8556 10.4967 11.2153 10.2563C11.575 10.016 11.8554 9.67433 12.021 9.27462C12.1866 8.87491 12.2299 8.43507 12.1455 8.01074C12.0611 7.58641 11.8527 7.19663 11.5468 6.89071C11.2409 6.58478 10.8511 6.37644 10.4268 6.29203C10.0024 6.20763 9.56259 6.25095 9.16288 6.41651C8.76317 6.58208 8.42153 6.86246 8.18116 7.22219C7.94079 7.58192 7.8125 8.00485 7.8125 8.4375" stroke="#6A7381" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                    </span>
                </ChakraTooltip>
            </div>
        </div>
    )
}

const ModalLeftSection = ({ data, setData }) => {
    const flags = useFlags();
    const isShowProductToDisplayOption = flags[LAUNCH_DARKLY_ENABLE_WIX_PRODUCT_OPTIONS_TO_DISPLAY];
    let min = 0
    let max = 100

    const handleChange = (label, value) => {
        setData({ ...data, [label]: value })
    }

    return (
        <div>

            <Title step={t('step_1')} title={t('choose_discount_value')} tooltipText={t('here_you_can_select_discount_on_upsell_widget_you_can_change_these_settings_in_our_editor_anytime')} />

            <NumberInput className={styles.input_button} onChange={(valueString) => { if ((Number(valueString) >= Number(min) && Number(valueString) <= Number(max)) || (!min || Number(valueString) <= Number(max)) || (!max || Number(valueString) >= Number(min)) || (!min && !max)) { handleChange('discount', Number(valueString)) } }} placeholder='50' value={data.discount} min={min} max={max}>
                <InputGroup>
                    <NumberInputField className={styles.number_input} placeholder={'50'} />
                    <InputRightElement className={styles.right_element}>%</InputRightElement>
                </InputGroup>
            </NumberInput>

            <Title step={t('step_2')} title={t('select_product_type_for_upsell')} tooltipText={t('here_you_can_select_the_product_type_for_the_upsell_widget')} />

            <RadioGroup onChange={(e) => handleChange('radioValue', e)} value={data.radioValue}>
                <Stack direction='column' className={styles.radio_button}>
                    {isShowProductToDisplayOption &&
                        <>
                            <Radio value='SAME_CATEGORIES' className={styles.radio_item}>{t('from_the_same_category')}</Radio>
                            {/* <Radio value='FREQUNTLY_BOUGHT_TOGETHER' className={styles.radio_item}>{t('frequently_bought_together')}</Radio>
                            <Radio value='FREQUENTLY_VIEWED_TOGETHER' className={styles.radio_item}>{t('frequently_viewed_together')}</Radio> */}
                            <Radio value='BEST_SELLERS' className={styles.radio_item}>{t('best_sellers')}</Radio>
                        </>
                    }
                </Stack>
            </RadioGroup>


        </div>
    )

}


const Footer = ({ onClose, onSubmit, saveBtnTitle, className, data, submitText }) => {
    return (
        <div className={`${styles.footer_wrap} ${className ? className : ''}`}>
            <Button className={PLATFORM === PlatformType.Wix ? `${styles.decline_btn} ${styles.wix_btn} ` : `${styles.decline_btn}`} colorScheme='blackAlpha' variant={'ghost'} mr={3} onClick={() => onClose(data)}>
                {t('set_up_manually')}
            </Button>
            {data?.radioValue === 'SPECIFIC_PRODUCT' && submitText ?
                <Button className={styles.accept_btn} onClick={() => onSubmit(data)} >{submitText}</Button>
                :
                <Button className={styles.accept_btn} onClick={() => onSubmit(data)} >{saveBtnTitle ? saveBtnTitle : t('activate')}</Button>
            }

        </div>
    )
}


const VersionB = (props) => {
    const { queryParams } = useContext(PageData);
    const [data, setData] = useState({ radioValue: shouldOpenEditorFromUrl() ? queryParams?.product : 'SAME_CATEGORIES' })
    const [isChangedNow, setIsChangedNow] = useState(false)

    const addTime = (time) => {
        if (Math.floor(time) === 0 && !isChangedNow) {
            setIsChangedNow(true)
            setData({ ...data, playedSeconds: time, played: data.played ? data.played + 1 : 1 });
            setTimeout(() => {
                setIsChangedNow(false)
            }, 1000)
        } else {
            setData({ ...data, playedSeconds: time });
        }
    }

    useEffect(() => {
        if (props.defaultDiscount) {
            setData({ ...data, discount: props.defaultDiscount })
        }
    }, [props.defaultDiscount])

    return (
        <>
            <div className={styles.header_wrap_b}>
                <div className={styles.header_text}>{t('welcome_to_reconvert')}</div>
                <div className={styles.header_description}>{t('you_are_clicks_away_from_boosting_your_sales_on_checkout_page')}</div>
            </div>

            <div className={styles.body_wrap}>
                <ModalLeftSection data={data} setData={setData} />
                <div>
                    <ReactPlayer
                        playing={true}
                        playsinline={true}
                        width={412}
                        height={224}
                        url={ONBOARDING_VIDEO_URL}
                        muted={true}
                        loop={true}
                        controls={true}
                        onProgress={(progress) => {
                            addTime(progress.playedSeconds)
                        }}
                        onDuration={(progress) => setData({ ...data, totalSec: progress })}
                    />
                </div>

            </div>

            <Footer {...props} data={data} submitText={t('choose_product')} />
        </>
    )
}



export default RenderModalContent