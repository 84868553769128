import React from 'react';

const Banner = () => {
  return (
    <svg
      width="148"
      height="60"
      viewBox="0 0 148 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="18.5" y="22.5" width="111" height="15" rx="4.5" fill="#FCFDFF" />
      <rect
        x="18.5"
        y="22.5"
        width="111"
        height="15"
        rx="4.5"
        stroke="#9EA7B6"
      />
      <circle cx="26" cy="30" r="3" fill="#D9D9D9" />
      <rect x="39" y="27" width="86" height="2" rx="1" fill="#DADEE6" />
      <rect x="39" y="31" width="65.4348" height="2" rx="1" fill="#DADEE6" />
    </svg>
  );
};

export default Banner;
