import React from 'react';

const ProductUpsell = () => {
  return (
    <svg
      width="102"
      height="63"
      viewBox="0 0 102 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="3.49976"
        width="44.4831"
        height="32"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect
        x="47.4829"
        y="5.99976"
        width="38.5618"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <rect
        x="47.4829"
        y="10.9998"
        width="29.3405"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <rect
        x="47.4829"
        y="19.9998"
        width="38.5618"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <rect
        x="47.4829"
        y="24.9998"
        width="38.5618"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <rect
        x="47.4829"
        y="29.9998"
        width="38.5618"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <rect
        x="0.5"
        y="42.4998"
        width="101"
        height="9"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
    </svg>
  );
};

export default ProductUpsell;
