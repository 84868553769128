import React from 'react';

const Text = () => {
  return (
    <svg
      width="148"
      height="38"
      viewBox="0 0 148 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="28" y="23" width="92" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="37.5" y="29" width="73" height="3" rx="1.5" fill="#DADEE6" />
      <rect x="37.5" y="35" width="73" height="3" rx="1.5" fill="#DADEE6" />
      <path
        d="M63.7244 11.8762V9.70475H72.0305V28.2952H69.5918C68.5473 28.2952 67.6224 29.0708 67.6224 30.1309C67.6224 31.1911 68.5473 31.9667 69.5918 31.9667H78.4081C79.4526 31.9667 80.3775 31.1911 80.3775 30.1309C80.3775 29.0708 79.4526 28.2952 78.4081 28.2952H75.9693V9.70475H84.2754V11.8762C84.2754 12.9363 85.2003 13.7119 86.2448 13.7119C87.2893 13.7119 88.2142 12.9363 88.2142 11.8762V8.75952C88.2142 7.20959 86.8485 6.03333 85.2652 6.03333H62.7346C61.1514 6.03333 59.7856 7.20959 59.7856 8.75952V11.8762C59.7856 12.9363 60.7106 13.7119 61.755 13.7119C62.7995 13.7119 63.7244 12.9363 63.7244 11.8762Z"
        fill="#9EA7B6"
        stroke="white"
      />
    </svg>
  );
};

export default Text;
