import React from 'react';

const Bundle = () => {
  return (
    <svg
      width="148"
      height="57"
      viewBox="0 0 148 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.998047"
        y="0.5"
        width="43"
        height="33"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect x="0.498047" y="36" width="44" height="3" rx="1.5" fill="#DADEE6" />
      <rect
        x="0.498047"
        y="41"
        width="34.7091"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <path
        d="M48.4452 22.4674V24.0505C48.4452 24.2987 48.2439 24.5 47.9956 24.5C47.7474 24.5 47.5461 24.2987 47.5461 24.0505V22.4674H45.9678C45.7084 22.4674 45.498 22.2571 45.498 21.9976C45.498 21.7381 45.7084 21.5278 45.9678 21.5278H47.5461V19.9495C47.5461 19.7013 47.7474 19.5 47.9956 19.5C48.2439 19.5 48.4452 19.7013 48.4452 19.9495V21.5278H50.0282C50.2877 21.5278 50.498 21.7381 50.498 21.9976C50.498 22.2571 50.2877 22.4674 50.0282 22.4674H48.4452Z"
        fill="#9EA7B6"
      />
      <rect
        x="51.998"
        y="0.5"
        width="43"
        height="33"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect x="51.498" y="36" width="44" height="3" rx="1.5" fill="#DADEE6" />
      <rect
        x="51.498"
        y="41"
        width="34.7091"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <path
        d="M99.4452 22.4674V24.0505C99.4452 24.2987 99.2439 24.5 98.9956 24.5C98.7474 24.5 98.5461 24.2987 98.5461 24.0505V22.4674H96.9678C96.7084 22.4674 96.498 22.2571 96.498 21.9976C96.498 21.7381 96.7084 21.5278 96.9678 21.5278H98.5461V19.9495C98.5461 19.7013 98.7474 19.5 98.9956 19.5C99.2439 19.5 99.4452 19.7013 99.4452 19.9495V21.5278H101.028C101.288 21.5278 101.498 21.7381 101.498 21.9976C101.498 22.2571 101.288 22.4674 101.028 22.4674H99.4452Z"
        fill="#9EA7B6"
      />
      <rect
        x="103.002"
        y="0.5"
        width="44"
        height="32.2609"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
      <rect x="102.498" y="36" width="44" height="3" rx="1.5" fill="#DADEE6" />
      <rect
        x="102.498"
        y="41"
        width="34.7091"
        height="3"
        rx="1.5"
        fill="#DADEE6"
      />
      <rect
        x="0.5"
        y="47.5"
        width="147"
        height="9"
        rx="4.5"
        fill="#FCFDFF"
        stroke="#9EA7B6"
      />
    </svg>
  );
};

export default Bundle;
