import React, { useCallback, useState } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { Button, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import styles from './OnBoardingModal.module.css'
import RenderModalContent from './RenderModalContent'
import { trackSegmentsService, updateOnboardStatus } from '../../../Service'
import { PageData } from '../../../ContextAPI/Context'
import { updateTargetedValue, CreatedNewObj, getFormatDateandTime, getTargetedValue, shouldOpenEditorFromUrl } from '../../../Utils/Common'
import { OnBoardingWixType, PlatformType, WidgetType } from '../../../Constant'
import checkout_wix_editor from '../../../Mock/checkout_wix_editor.json';
import { t } from 'i18next'
import { PLATFORM } from '../../../config'



const OnBoardingModal = () => {
    const { onBoardingModalData, setOnBoardingModalData, configSetting, systemSetting, unifiedEditor, setIsSelectProduct, updateData, setIsAddSection, setSelectedSection, setIsOnboardSubmit, isOnboardSubmit, setAddWidgetByTarget, submitData, setIsPublishedData, getWixDashboardUrl, queryParams } = useContext(PageData)
    const [isShowModal, setIsShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [defaultDiscount, setDefaultDiscount] = useState('')
    const [fieldData, setFieldData] = useState({})

    const fetchDiscount = useCallback(() => {
        let string = `defaultSections.[type:UPSELL].blocks.[type:PRICE_AND_DISCOUNT].discount.discountValue`
        let discount = getTargetedValue(unifiedEditor, string)
        setDefaultDiscount(shouldOpenEditorFromUrl() ? queryParams?.maxDiscount : discount)
    }, [unifiedEditor, queryParams?.maxDiscount])

    useEffect(() => {
        if (onBoardingModalData.showModal) {
            if (queryParams.autoActivation === 'true' && shouldOpenEditorFromUrl()) {
                const data = {
                    radioValue: queryParams.product,
                    discount: Number(queryParams.maxDiscount),
                    totalSec: 0,
                    playedSeconds: 0,
                    played: 0,
                };
                handleSubmit(data);
            } else {
                setIsShowModal(true)
                fetchDiscount()
            }
        }
        console.warn('show', onBoardingModalData.showModal);

    }, [onBoardingModalData, fetchDiscount, queryParams.maxDiscount, queryParams.product, queryParams.autoActivation, shouldOpenEditorFromUrl])

    const onClose = async (trackClick, data) => {
        if (trackClick === true) {
            let type = onBoardingModalData.type

            handleAddSection(false);
            trackButtonClick(type, data, OnBoardingWixType.SetUpManually)
            trackTime(type, data)
            setOnBoardingModalData(prev => ({ ...prev, setUpManually: true }));
        }
        setOnBoardingModalData(prev => ({ ...prev, showModal: false }));
        setIsShowModal(false);
    }

    const handleAddSection = (isSubmit) => {
        setIsAddSection({ isAdd: true, handleSubmit: isSubmit })
        const schema = checkout_wix_editor;
        const defaultWidgetType = WidgetType.MultiProductUpsell;
        const addSectionOptions = schema.addSectionOptions;
        const index = addSectionOptions.findIndex((item) => item.value.includes(defaultWidgetType))
        setAddWidgetByTarget(addSectionOptions[index].value);
    }

    const handleSubmit = async (data) => {
        let type = onBoardingModalData.type
        setFieldData(data)
        trackButtonClick(type, data, OnBoardingWixType.Activate)
        handleAddSection(true)
    }

    const handleOnboardSubmit = async () => {
        let data = { ...fieldData }
        if (fieldData) {
            let type = onBoardingModalData.type
            // trackButtonClick(type, data, 'Activate')
            setLoading(true)
            setIsSuccess(true)
            let latestPrepurchaseSettings = await CreatedNewObj(configSetting[configSetting.length - 1]);
            let discountTarget = 'widgets.[type:MULTI_PRODUCT_UPSELL].widgets.[type:UPSELL].blocks.[type:PRICE_AND_DISCOUNT].discount.enabled'
            let amountTarget = 'widgets.[type:MULTI_PRODUCT_UPSELL].widgets.[type:UPSELL].blocks.[type:PRICE_AND_DISCOUNT].discount.discountValue'
            let productTypeTarget = 'widgets.[type:MULTI_PRODUCT_UPSELL].widgets.[type:UPSELL].upsellInfo.type'

            try {
                let extention = {}

                extention = latestPrepurchaseSettings

                if (data.discount && data.discount > 0) {
                    extention = await updateTargetedValue(extention, discountTarget, true, true);
                    extention = await updateTargetedValue(extention, amountTarget, data.discount, true);
                }

                extention = await updateTargetedValue(extention, productTypeTarget, data.radioValue, true);

                latestPrepurchaseSettings = extention

                updateData(latestPrepurchaseSettings);
                trackTime(type, data)

                if (data?.radioValue === 'SPECIFIC_PRODUCT') {
                    const multiUpsell = extention.widgets.find((widget) => widget.type === WidgetType.MultiProductUpsell)
                    const upsell = multiUpsell.widgets.find((widget) => widget.type === WidgetType.Upsell)
                    setSelectedSection({ id: upsell?.id, isOpen: true });

                    setIsSelectProduct(true)
                    onClose();
                } else {
                    latestPrepurchaseSettings.status = true
                    let isDataSaved = submitData(latestPrepurchaseSettings);

                    setLoading(false)
                    if (!isDataSaved) {
                        setIsSuccess(true)
                        setOnBoardingModalData({ ...onBoardingModalData, isError: true })
                    } else {
                        setIsPublishedData(true)
                    }

                }
            } catch (err) {
                console.log('error', err);
                setIsSuccess(false)
                setLoading(false)
                setOnBoardingModalData({ ...onBoardingModalData, isError: true })
            }
        }
    }

    useEffect(() => {
        if (onBoardingModalData.setUpManually && isOnboardSubmit) {
            handleUpdateOnboardStatus();
            setOnBoardingModalData(prev => ({ ...prev, setUpManually: false }));
            setIsOnboardSubmit(false);
        } else if (isOnboardSubmit) {
            handleOnboardSubmit();
            setIsOnboardSubmit(false);
        }
    }, [isOnboardSubmit, onBoardingModalData]);

    const trackTime = async (type, obj = {}) => {
        let fields = { onboardingVersion: type, playedSeconds: (obj.played > 0 ? ((obj.played - 1) * obj.totalSec) : 0) + obj.playedSeconds, played: obj.played > 0 ? obj.played - 1 : 0, platform: 'WIX', userId: systemSetting.instance_id, storeUrl: systemSetting.storeUrl, email: systemSetting.email }
        let data = {
            id: systemSetting.instance_id,
            event: 'Video watching time',
            data: fields
        }
        console.log('track video time', data)
        // await trackSegmentsService(data,
        //     res => {
        //         console.log(res);
        //     },
        //     err => {
        //         console.log(err);
        //     });

    };

    const trackButtonClick = async (type, obj, buttonType) => {
        let fields = { onboardingVersion: type, timestamp: getFormatDateandTime(), platform: 'WIX', userId: systemSetting.instance_id, buttonType: buttonType, storeUrl: systemSetting.storeUrl, email: systemSetting.email }
        if (obj) {
            fields = { ...fields, maxDiscount: obj.discount, productType: obj.radioValue }
        }
        let data = {
            id: systemSetting.instance_id,
            event: 'Onboarding popup click',
            data: fields
        }
        console.log('track button click', data)

        try {
            const res = await trackSegmentsService(data)
            console.log(res);
        } catch(error){
            console.log(error);
        }
        if(buttonType === OnBoardingWixType.Activate) {
            handleUpdateOnboardStatus();
        }
    };

    const handleUpdateOnboardStatus = async () => {
        let data = {
            editor_onboarding_status: true,
            instance_id: systemSetting.instance_id,
            checkout_status: 1,
        };
        await updateOnboardStatus(
            data,
            async (res) => {
                if (res.status === 200) {
                    await trackSegmentsService({
                        id: systemSetting.instance_id,
                        event: 'app enabled',
                        data: {
                            userid: systemSetting.instance_id,
                            email: systemSetting.email,
                            platform: 'Wix',
                            location: 'Onboarding',
                            'date & time': new Date(),
                            "store vertical - primary": systemSetting?.verticalPrimary,
                            "store vertical - secondary": systemSetting?.verticalSecondary,
                        },
                        traits: {
                            "store vertical - primary": systemSetting?.verticalPrimary,
                            "store vertical - secondary": systemSetting?.verticalSecondary,
                        },
                    });
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const redirectToDashboard = () => {
        if (getWixDashboardUrl()) {
            window.location.href = getWixDashboardUrl();
        } else {
            window.close();
        }
    }

    return (
        <div>
            {isSuccess ?
                <Modal isOpen={isShowModal} onClose={onClose} size={'lg'} closeOnOverlayClick={false}>

                    <ModalOverlay />
                    <ModalContent>
                        <div className={styles.success_content_wrap}>
                            {loading ?
                                <div className={styles.loader_wrap}>
                                    <div className={styles.loader}>
                                    </div>
                                </div>
                                :
                                <>
                                    <div>
                                        <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M63.8281 38.5938L42.0449 59.375L31.1719 48.9844" stroke="#121417" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M47.5 83.125C67.1751 83.125 83.125 67.1751 83.125 47.5C83.125 27.8249 67.1751 11.875 47.5 11.875C27.8249 11.875 11.875 27.8249 11.875 47.5C11.875 67.1751 27.8249 83.125 47.5 83.125Z" stroke="#121417" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </div>
                                    <div className={styles.title}>
                                        {t('success')}
                                    </div>
                                    <div className={styles.description}>
                                        {t('widget_successfully_activated_on_your_checkout_page')}
                                    </div>

                                    <div className={styles.footer_wrap}>
                                        <Button className={PLATFORM === PlatformType.Wix ? `${styles.decline_btn} ${styles.wix_btn} ` : `${styles.decline_btn}`} colorScheme='blackAlpha' variant={'ghost'} mr={3} onClick={onClose}>
                                            {t('go_to_checkout_editor')}
                                        </Button>
                                        <Button className={styles.accept_btn} onClick={redirectToDashboard} >{t('go_to_dashboard')}</Button>
                                    </div>
                                </>
                            }

                        </div>
                    </ModalContent>

                </Modal>
                :
                <Modal isOpen={isShowModal} onClose={onClose} size={'4xl'} closeOnOverlayClick={false}>

                    <ModalOverlay />
                    <ModalContent>
                        <div className={styles.content_wrap}>
                            <RenderModalContent defaultDiscount={defaultDiscount} type={onBoardingModalData.type} onClose={(data) => onClose(true, data)} onSubmit={handleSubmit} />
                        </div>
                    </ModalContent>

                </Modal>
            }
        </div>
    )

}

export default OnBoardingModal