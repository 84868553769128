import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from 'react';
import '../../../../../Style/theme-block-section.css';
import { PageData } from '../../../../../ContextAPI/Context';
import { getTargetedValue, makeRightBarLinks } from '../../../../../Utils/Common';
import AddBlock from '../AddBlock';
import DroppableBlocks from '../DroppableBlocks';
import VisibilityButtonGroup from '../VisibilityButtonGroup';
import Icon, { IconSource } from '../../../../../Shared/Components/Icon';
import { t } from 'i18next';
import { componentModel } from '../../../Models/Component.model';
import { PLATFORM } from '../../../../../config';
import { PlatformType, ThreeSectionLayout, ThreeSectionLayoutSettings } from '../../../../../Constant';
import { DragDropContext } from 'react-beautiful-dnd';
/*
Rendering widget which may contains blocks
*/
const Accordion = ({ items, index, onClick,addBlock, parentWidgetId,  parentWidgetType, target, provided, isDragging, isDefaultOpen, isParentOpen, isParentVisible = true }) => {
    const divRef = useRef();
    const [isOpen, setOpen] = useState(false);
    const [openId, setOpenId] = useState({ id: '', status: false });
    const [widgetData, setWidgetData] = useState({});
    const [isHide, setIsHide] = useState(false);
    const {getUniqueByType, unifiedEditor, selectedSection, isRequiredDataFilled, setSelectedSection, selectedLink, setSelectedLink, setRightBarLinks, previewSelectedSections, setPreviewSelectedSection, updateData, latestConfigSetting } = useContext(PageData);

    const WidgetLayout = getTargetedValue(latestConfigSetting, 'widgets.[id:{TOP_WIDGET_ID}]', '', items.id);
    const isSmallLayout = WidgetLayout?.upsellInfo?.layout === "SMALL_LAYOUT_ONE" || WidgetLayout?.upsellInfo?.layout === "SMALL_LAYOUT_TWO";

    const uniqueObjects = isSmallLayout ? getUniqueByType(widgetData) : widgetData;

    let checkLength;
    try {
        checkLength = items.blocks.length;
    } catch (err) {
        checkLength = 0;
    }

    // If Parent accordion is open then it will be open by default
    useEffect(() => {
        if (isParentOpen || isDefaultOpen) {
            setOpen(true);
        }
    }, [isParentOpen, isDefaultOpen]);

    useEffect(() => {
        if (selectedSection?.id === items?.id && divRef.current) {
            setOpen(selectedSection.isOpen);
            setIsHide(false);
            divRef.current.click();
            setSelectedSection();
        }
    }, [items]);

    useEffect(() => {
        if (selectedLink?.id === items?.id && divRef.current) {
            divRef.current.click();
            divRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            setSelectedLink({});
        } else if (selectedLink?.parentSectionID === items?.id) {
            setSelectedLink({});
            setOpen(true);
        }
         
    }, [selectedLink])

    useEffect(() => {
        if (isDefaultOpen && divRef.current) {
            divRef.current.click()
        }
    }, [isDefaultOpen]);

    useEffect(() => {
        if (isDefaultOpen && !selectedSection?.id) {
            setOpen(true);
        }
         
    }, [isDefaultOpen]);

    useEffect(() => {
        setWidgetData(items);
    }, [unifiedEditor, latestConfigSetting, items]);

    const handleMouseEvent = (e) => {
        e.stopPropagation();
        setIsHide(true);
        setSelectedSection({ id: items?.id, isOpen: isOpen });
    }

    const handleDropdownClick = (e) => {
        e.stopPropagation();
        if (isHide) {
            setIsHide(false);
            setOpen(true);
        } else {
            setOpen(!isOpen);
        }
    }

    const handleClick = (e) => {
        Array.from(document.querySelectorAll('.selected-item-active')).forEach((el) => el.classList.remove('selected-item-active'));

        if (selectedSection?.id !== items?.id && previewSelectedSections.id === items.id && !previewSelectedSections.isBlock) {
            setPreviewSelectedSection({ id: null, parentId: null });
            onClick();
        } else {
            const modelData = {
                widgetId: items.id,
                parentWidgetId: parentWidgetId,
                componentType: items.type,
                count: items.count,
                minCount: items.minCount,
                target: target,
                parentWidgetType: parentWidgetType,
            }
            onClick(e, componentModel(modelData));
            e.target.closest('.accordion-title-outer').classList.add('selected-item-active');
            const blocks = items?.blocks?.length > 0 ? isSmallLayout ? getUniqueByType(items)?.blocks : items?.blocks : [];
            let links = makeRightBarLinks(blocks, items.id, 'block', widgetData?.seprators?.length > 0);
            if (ThreeSectionLayout.includes(items.type)) {
                setPreviewSelectedSection({ id: parentWidgetId, parentId: parentWidgetId, isBlock: true });
            } else {
                setPreviewSelectedSection({ id: items.id, parentId: parentWidgetId });
            }
            setRightBarLinks(links);
            if (isHide) {
                setIsHide(false);
            } else {
                if (selectedSection?.id) {
                    setOpen(selectedSection.isOpen);
                } else {
                    setOpen(true);
                }
            }
        }
    }

    const isShowCaret = useCallback(() => {
        if (items?.blocks?.length > 0) {
            return true;
        } else if (ThreeSectionLayout.includes(items.type)) {
            return true;
        }
        return false;
    }, [items]);

    const handleDragEnd = (result, items) => {
        if (!result.destination) return;
        let target = items.target
        let arr = getTargetedValue(latestConfigSetting, `${target}.blocks`, items.id, parentWidgetId);
        const element = arr.splice(result.source.index, 1)[0];
        arr.splice(result.destination.index, 0, element);
        updateData(latestConfigSetting);
    }
    const isVisibleParent = useMemo(() => {
        if (ThreeSectionLayout.includes(items.type)) {
            const target = ThreeSectionLayoutSettings[items.type].target;
            return getTargetedValue(latestConfigSetting, target, items.id, parentWidgetId);
        }
        return getTargetedValue(latestConfigSetting, `${items.target}.${items.condition}`, items?.id, parentWidgetId);
    }, [latestConfigSetting, items, parentWidgetId]);

      
    return (
        <ul key={index} style={{ backgroundColor: isDragging ? 'white' : 'none', boxShadow: isDragging ? '2px 2px 5px lightgrey' : 'none' }}>
            <li className={checkLength > 0 ? `submenu-outer` : null}>
                <div
                    className='accordion-title-outer'
                    ref={divRef}
                    onClick={e => {
                        if (isRequiredDataFilled()) {
                            if (ThreeSectionLayout.includes(items.type)) {
                                handleDropdownClick(e)
                            } else {
                                handleClick(e)
                            }
                        }
                    }}
                >
                    <div className='accordion-title-container' >
                        {
                            isShowCaret() &&
                            <div onClick={(e) => { handleDropdownClick(e) }} className={`accordion-dropdown-icon ${!isHide || isOpen ? 'open' : ''}`}>
                                <span className={!isHide && isOpen ? 'accordion-dropdown-icon-open' : 'accordion-dropdown-icon-close'}>
                                    <Icon source={IconSource.Caret} iconAlt={IconSource.Caret} />
                                </span>
                            </div>
                        }

                        <span
                            style={isShowCaret() ? { paddingLeft: '0px' } : ({})}
                            className='accordion-title-span accordion-title'
                        >
                            <Icon iconName={widgetData.icon} iconAlt={widgetData.sectionName} className={PLATFORM === PlatformType.Shopify ? 'shopify_icon' : ''} />
                            {t(widgetData.sectionName)}
                        </span>
                    </div>
                    {<VisibilityButtonGroup handleMouseEvent={handleMouseEvent} provided={provided} item={widgetData} widgetId={widgetData.id} parentWidgetId={parentWidgetId} target={target} isParentVisible={isParentVisible} onClick={onClick} />}
                </div>

                <div className={`${!isOpen || isHide ? 'collapsed none' : 'list'}`} >
                    {ThreeSectionLayout.includes(items.type) ? (
                        <DroppableBlocks
                            parentWidgetId={parentWidgetId}
                            isParentVisible={isParentVisible ? isVisibleParent : isParentVisible}
                            widgets={widgetData}
                            isOpen={isOpen}
                            onClick={onClick}
                            parentWidgetType={parentWidgetType}
                        />

                    ) : (
                        <DragDropContext onDragEnd={(event) => handleDragEnd(event, items)} >
                            <DroppableBlocks
                                parentWidgetId={parentWidgetId}
                                isParentVisible={isParentVisible ? isVisibleParent : isParentVisible}
                                widgets={uniqueObjects}
                                isOpen={isOpen}
                                onClick={onClick}
                                parentWidgetType={parentWidgetType}
                                WidgetLayoutId={items.id}
                            />
                        </DragDropContext>
                    )}

                    {widgetData.blocks &&
                        <AddBlock
                            isOpen={isOpen}
                            addBlock={addBlock }
                            id={`addblock${''}`}
                            widgetType={widgetData.type}
                            parentWidgetType={parentWidgetType}
                            parentWidgetId={parentWidgetId}
                            widgetId={widgetData.id}
                            openId={{ openId, setOpenId }}
                            target={target}
                            WidgetLayoutId={items.id}
                            uniqueObjects={uniqueObjects?.blocks}
                        />
                    }
                </div>

            </li>
        </ul>
    );
};

export default Accordion;