import { useContext, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { t } from 'i18next';

import { PageData } from '../../../../../ContextAPI/Context';
import { Ids, getConditionalBlocks, getOptions, getTargetedValue, getUniqueUpsellName } from '../../../../../Utils/Common';
import { CDN_BASE_URL, PLATFORM } from '../../../../../config';
import { IconSource } from '../../../../../Shared/Components/Icon';
import { Popover } from '../../../../../Shared/Components';
import { DefaultLayoutForWidget, UpsellBlocks, WidgetType } from '../../../../../Constant';
import { getNumberOfWidget } from '../../Utils/getNumberOfWidget';
import { PlatformType } from '../../../../../Constant';

/* Add widget button to add widget in top level widgets */
const AddWidget = ({
  widgets,
  defaultOptions,
  parentWidgetId,
  parentWidgetType,
  target
}) => {

  const [options, setOptions] = useState([]);
  const {
    updateData,
    configSetting,
    unifiedEditor,
    isRequiredDataFilled,
    setIsShowWidgetLimitModal,
    latestConfigSetting,
    previewSelectedSections,
    previewType,
    updateRightBarLinks
  } = useContext(PageData);

  useEffect(() => {
    if (defaultOptions) {
      let listOptions = getOptions(defaultOptions, unifiedEditor.widgets);
      setOptions(
        listOptions.map((item) => {
          return { label: item.sectionName, value: item.type, icon: item.icon };
        })
      );
    }
  }, [defaultOptions, widgets, unifiedEditor.widgets]);

  const handleChange = (component, allowSeparateQuantitySelection) => {
    if (isRequiredDataFilled()) {
      let latestSetting = cloneDeep(configSetting[configSetting.length - 1]);
      const numberOfWidget = getNumberOfWidget(latestSetting?.widgets);
      if (PLATFORM === PlatformType.Wix && numberOfWidget >= 10) {
        setIsShowWidgetLimitModal(true);
      } else {
        
        const subTarget = Object.values(WidgetType).includes(component) ? `.widgets` : `.blocks`;
        const customTarget = `defaultSections.[type:${component}]`;

        let targetedValue = getTargetedValue(unifiedEditor, customTarget);

        let add_widget_object = getTargetedValue(latestSetting, target + subTarget, '', parentWidgetId);
        let whole_Widget = getTargetedValue(latestSetting, target, '', parentWidgetId);

        let addWidget = cloneDeep(targetedValue);
        addWidget.id = Ids();
        addWidget.parentType = parentWidgetType;
        if (allowSeparateQuantitySelection === false) {
          const index = addWidget.blocks.findIndex(data => data.type === UpsellBlocks.QuantitySelector)
          if (index > -1) {
            addWidget.blocks.splice(index, 1)
          }
        }

        /***
         * When added an Upsell widget inside a multi-upsell widget, we need to update the default value of "noOfProducts" to 1 because only one product display in upsell widget, 
         * But the default value of "noOfProducts" is 4 for bundle and recomandations.
         *  */
        if (addWidget.type === WidgetType.Upsell) {
          const defaultData = defaultOptions.find(option => option.type === addWidget.type);
          addWidget.upsellInfo.noOfProducts = defaultData?.noOfProducts ?? addWidget.upsellInfo.noOfProducts;
          // Add default type of "layout" according to the parent widget type.
          addWidget.upsellInfo.layout = DefaultLayoutForWidget[previewType][parentWidgetType] ?? addWidget.upsellInfo.layout;

           // Remove noOfProducts and layout field from upsell when parent widget type is bundle, because these properties manage from bundle.
           if(parentWidgetType === WidgetType.Bundle) {
            delete addWidget.upsellInfo.noOfProducts;
            delete addWidget.upsellInfo.layout;
          }

          const blocks = unifiedEditor.widgets.find(e => e.type === addWidget.type)?.blocks;
          
          addWidget.blocks = addWidget?.blocks?.map(e => ({ ...e, id: Ids() })) || [];
          // Check the blocks condition according to the parent widget type.
          addWidget.blocks = getConditionalBlocks(addWidget.blocks, blocks, parentWidgetType);
        }

      if (addWidget.name && add_widget_object) {
        addWidget.name = getUniqueUpsellName(addWidget.name, addWidget.type, add_widget_object);
      }
      addWidget.isVisible = true;
      add_widget_object.push(addWidget);
      if (addWidget.type === WidgetType.Upsell && parentWidgetType === WidgetType.Bundle) {
        const currentLength = add_widget_object.filter((data)=> data.type === WidgetType.Upsell)?.length ?? 1;
        whole_Widget.noOfProducts =  whole_Widget.noOfProducts < currentLength ? currentLength : whole_Widget.noOfProducts
      }
      // used to dynamically update the right bar links
      updateRightBarLinks(latestSetting,parentWidgetType,parentWidgetId,null);
      updateData( {...latestSetting, sectionAdded: true } );
    }
  }
}

useEffect(() => {
  if(previewSelectedSections.id === parentWidgetId) {
    const selectedWidget = getTargetedValue(latestConfigSetting, target , '', parentWidgetId);
    const upsellCount = selectedWidget?.widgets && selectedWidget.widgets.filter(widget => widget.type === WidgetType.Upsell).length;
    if(upsellCount && selectedWidget?.noOfProducts > upsellCount) {
      handleChange(WidgetType.Upsell, selectedWidget?.allowSeparateQuantitySelection);
    }
  }
},[latestConfigSetting, parentWidgetId, previewSelectedSections]);

  return (
    <ul>
      {options.length > 0 ? (
        <li className={`add-section-wrap sub-menu collapsed`}>
          <Popover 
              options={options || []}
              onClick={handleChange}
              iconSource={IconSource.Plus}
              title={PLATFORM === PlatformType.Wix ? t('add_section') : t('add_widget')}
              titleClassName={'add-section-title-link'}
            />
        </li>
      ) : (
        <div className={`disabled-add-button`}>
           <img src={CDN_BASE_URL + `assets/plus-disabled.svg`} alt={`Add widget`} />
           {PLATFORM === PlatformType.Wix ? t('add_section') : t('add_widget')}
        </div>
      )}
    </ul>
  );
};
export default AddWidget;
