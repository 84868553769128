import { Icons, Typography } from '@reconvert/react-ui-component';
import React, { useState } from 'react';

const AssistanceTooltip = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(true);

  return (
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        width: '100%',
      }}
    >
      {children}
      {showTooltip && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: '#4D9EEA',
            color: 'white',
            borderRadius: '4px',
            fontSize: '14px',
            whiteSpace: 'nowrap',
            zIndex: 10,
            transition: 'opacity 0.3s',
            top: `-77px`,
            left: `22px`,
          }}
        >
          <div style={{ padding: '12px' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <div>
                <Typography variant="body"> Need more widgets?</Typography>
                <Typography variant="body">
                  Just click here to add them.
                </Typography>
              </div>
              <div>
                <Icons.CloseIcon
                  onClick={() => setShowTooltip(false)}
                  height={'20px'}
                  width={'20px'}
                  cursor={'pointer'}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              content: '""',
              position: 'absolute',
              top: '100%',
              left: '50%',
              marginLeft: '-93px',
              borderWidth: '7px',
              borderStyle: 'solid',
              borderColor: '#4D9EEA transparent transparent',
            }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default AssistanceTooltip;
