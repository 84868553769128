import React, { useEffect, useState } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum'

import Layout from './Components/Layout';
import JsonData from './ContextAPI/Context';
import { APPLICATION_ID, CLIENT_TOKEN, ENV, PLATFORM, REACT_APP_LAUNCH_DARKLY_CLIENT_KEY, SERVICE } from './config';
import { useTranslation } from 'react-i18next';
// import Spinner from './Shared/Components/Spinner';
import { Typography } from "@reconvert/react-ui-component";
import { t } from 'i18next';

import './font';
import { PlatformType } from './Constant';

function App() {
  const [loading, setLoading] = useState(true);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage('en');

    if (PLATFORM === PlatformType.Wix) {
      document.body.classList.add("platform-wix");
    } else {
      document.body.classList.remove("platform-wix");
    }
  }, []);

  datadogRum.init({
    applicationId: APPLICATION_ID,
    clientToken: CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: SERVICE,
    env: ENV,
    version: '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
  console.log('_____Reconvert Loaded_____')
  return (
    // Wrapping unified editor's layout with unified json data
    <>
      {loading &&
        <div className='reconvert-spinner-icon'>
        <img src={'assets/reconvert-loader.gif'} style={{ width: "180px" }} />
        <Typography variant="subheading1" fontWeight={500} size={16}>
          {t("reconvert_magic_is_on_its_way")}
        </Typography>
      </div>
      }

      {/* {loading &&
        <div className='spinner-icon'>
          <Spinner size='large' label='Spinner example' color='cyan.500' thickness='4px'/>
        </div>
      } */}


      <JsonData handleLoading={setLoading}>
        {!loading && <Layout />}
      </JsonData>
    </>
  );
}

export default withLDProvider({
  clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_KEY,
  reactOptions: {
    useCamelCaseFlagKeys: false
  }
})(App);
