import { Button as PolarisButton } from '@shopify/polaris';
import { Button as ChakraButton } from '@chakra-ui/react';
import styles from './Button.module.css';

import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';
import Icon from '../Icon';


const Button = (props) => {
    const { disabled, plain, primary, fullWidth, prefixIcon, onClick, color, variant, className, children, isLoading } = props;
    return (
        <>
            {PLATFORM === PlatformType.Wix ?
                <ChakraButton isLoading={isLoading} onClick={onClick} leftIcon={prefixIcon && <Icon source={prefixIcon} />} isDisabled={disabled} className={`${className ? className : ''} ${variant === 'outline' ? styles.wix_button : `${styles.wix_button} ${styles.wix_button_filled}`} ${disabled ? styles.disabled_button : ''}`} colorScheme={color} variant={variant} >
                    {children}
                </ChakraButton>
                :
                <PolarisButton onClick={onClick} icon={prefixIcon && <Icon source={prefixIcon} />} disabled={disabled} plain={plain} primary={primary} fullWidth={fullWidth}>
                    {children}
                </PolarisButton>
            }
        </>
    )
}

export default Button;