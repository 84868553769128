import React, { useEffect } from 'react';
import { Toast as PolarisToast } from '@shopify/polaris';
import { PLATFORM } from '../../../config';
import { PlatformType, Toast_Message_Type } from '../../../Constant';
import { useToast } from '@reconvert/react-ui-component';
import { CloseButton } from '@chakra-ui/react';

const DURATION_MILLISECOND = 3000;

const Toast = ({ message, messageType, onDismiss }) => {
  const { success, error } = useToast();

  useEffect(() => {
    if (message && PLATFORM === PlatformType.Wix) {
            const toastFunction = messageType === Toast_Message_Type.Success ? success : error;

      toastFunction(message, {
        onCloseComplete: onDismiss,
        duration: DURATION_MILLISECOND,
        render: ({ onClose }) => (
          <div
            style={{
              backgroundColor:
                messageType === Toast_Message_Type.Success
                  ? 'var(--Neutral-900, #121417)'
                  : 'var(--Error-800, #C2291B)',
            }}
            className="wix_toast_container"
          >
            <div className="wix_toast_message">{message}</div>
            <CloseButton onClick={onClose} size="lg" color="white" />
          </div>
        ),
      });
    }
  }, [message, messageType]);

  return (
    <>
      {message && PLATFORM === PlatformType.Shopify && (
        <PolarisToast
          content={message}
          error={messageType === Toast_Message_Type.Error}
          duration={DURATION_MILLISECOND}
          onDismiss={onDismiss}
        />
      )}
    </>
  );
};

export default Toast;