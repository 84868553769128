import React, { useContext, useEffect, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Icons, Typography } from '@reconvert/react-ui-component';
import { createVariant } from '../../../Service';
import { Spinner } from '../../../Shared/Components';
import { PageData } from '../../../ContextAPI/Context';
import { eventsName } from '../../../Constant';

const CheckoutHeader = () => {
  const params = new URLSearchParams(window.location.search);
  const conditionId = params.get('conditionId');
  const slotNo = params.get('slotNo');
  const variantId = params.get('variant');

  const { variantList, fetchAllVariants, sendPostMessage } =
    useContext(PageData);

  useEffect(() => {
    if (variantList.length > 1) {
      const selectedVariant = variantList.find(
        (variant) => variant.id === variantId
      );

      selectedVariant &&
        sendPostMessage(eventsName.UpdateBreadcrumb, false, {
          variantName: selectedVariant?.name,
        });
    }
  }, [variantList]);

  useEffect(() => {
    if (conditionId && slotNo) {
      const queryParams = {
        conditionId: conditionId,
        slotNo: slotNo,
      };
      fetchAllVariants(queryParams);
    } else {
      console.error('Missing required query parameters');
    }
  }, [conditionId, slotNo]);

  return (
    <Box px="12px" bg="#fff" borderBottom="1px solid #CBD5E0">
      <Flex alignItems="center" overflowX={'auto'}>
        {variantList.length > 1 &&
          variantList.map((variant) => (
            <VariantBlock key={variant.id} variant={variant} />
          ))}
        <AddVariantButton />
      </Flex>
    </Box>
  );
};

const VariantBlock = ({ variant }) => {
  const {
    setupVariantById,
    setTriedToSelectVariant,
    sendPostMessage,
    selectedVariant,
    setSelectedVariant,
    isChangeDetected,
    setIsUnsavedChanges,
  } = useContext(PageData);

  const isSelected = selectedVariant === variant.id;

  return (
    <Box
      display="inline-block"
      onClick={() => {
        if (isChangeDetected) {
          setIsUnsavedChanges(true);
          setTriedToSelectVariant({
            variantId: variant.id,
            variantName: variant.name,
          });
          return;
        }
        setSelectedVariant(variant.id);
        setupVariantById({
          variantId: variant.id,
          isNewVariant: false,
        });
        sendPostMessage(eventsName.UpdateBreadcrumb, false, {
          variantName: variant.name,
        });
      }}
      borderBottom={isSelected ? '3px solid #003233' : '3px solid #fff'}
      cursor="pointer"
      padding="10px 38px"
      // _hover={{ '.pencil-icon': { visibility: 'visible' } }}
    >
      <Flex w="min-content" my="4px" justifyContent="center" align="center">
        <Flex flexDirection="column" gap="4px">
          <Flex alignItems="center" gap="4px">
            <Typography
              whiteSpace={'nowrap'}
              margin={0}
              variant="subheading2"
              color={isSelected ? '#003233' : '#6A7381'}
              fontSize={isSelected ? '16px' : '14px'}
              fontWeight={isSelected ? 500 : 400}
              lineHeight={isSelected ? '20px' : '16px'}
            >
              {variant.name}
            </Typography>
            <Typography
              whiteSpace={'nowrap'}
              margin={0}
              variant="small"
              color="#6A7381"
            >
              ({`${variant.abImpressionShare} %`})
            </Typography>
          </Flex>
        </Flex>
        {/* <Icons.PencilSimple
        className="pencil-icon"
        height="20px"
        width="20px"
        visibility="hidden"
        onClick={() => setIsVariantFormActivated(true)}
      /> */}
      </Flex>
    </Box>
  );
};

const AddVariantButton = () => {
  const params = new URLSearchParams(window.location.search);
  const conditionId = params.get('conditionId');
  const slotNo = params.get('slotNo');
  const slotId = params.get('slotId');

  const { setSelectedVariant, setVariantList, variantList, setupVariantById } =
    useContext(PageData);
  const [loading, setLoading] = useState(false);

  const buttonText =
    variantList?.length > 1
      ? loading
        ? 'Adding variant...'
        : 'Add variant'
      : loading
      ? 'Creating A/B testing...'
      : 'Create A/B test';

  const addVariant = async () => {
    if (loading || !conditionId || !slotNo) return;

    setLoading(true);

    const queryParams = { conditionId, slotId, slotNo };

    try {
      const { data } = await createVariant(queryParams);
      if (data) {
        setupVariantById({
          variantId: data[data?.length - 1]?.id,
          isNewVariant: false,
        });
        setSelectedVariant(data[data?.length - 1]?.id);
        setVariantList(data);
      }
    } catch (err) {
      console.error('Error fetching variants:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex p="12px">
      <Flex
        justifyContent="center"
        align="center"
        cursor={loading ? 'not-allowed' : 'pointer'}
        border="1px solid #363C45"
        height="min-content"
        borderRadius="16px"
        px="8px"
        py="4px"
        gap="4px"
        onClick={addVariant}
        _hover={{ bg: '#F0F3F7' }}
      >
        <Typography m={0} variant="xsmall">
          {buttonText}
        </Typography>
        {loading ? (
          <Spinner size={'small'} />
        ) : (
          <Icons.PlusCircle color="#121417" height={'12px'} width={'12px'} />
        )}
      </Flex>
    </Flex>
  );
};

export default CheckoutHeader;
