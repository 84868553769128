import React, { useContext } from 'react'
import styles from './Header.module.css'
import Divider from '../Divider';
import CloseButton from '../CloseButton';
import { t } from 'i18next';
import { PageData } from '../../../ContextAPI/Context';
import { PLATFORM } from '../../../config';
import { PlatformType } from '../../../Constant';

function Header({ label, onClose, showDivider, showHelpLink, rootStyles, dividerStyles }) {
    const { setHelpModalData, helpLinks } = useContext(PageData);

    const handleClick = () => {
        setHelpModalData(helpLinks[label]);
    }

    return (
        <>
            <div className={styles.heading_container} style={{ ...rootStyles }}>
                <h2>{t(label)}</h2>
                {onClose && <CloseButton onClick={onClose} />}
            </div>
            {showHelpLink && <span onClick={handleClick} className={PLATFORM === PlatformType.Wix ? `${styles.header_helper_link} ${styles.wix}`: styles.header_helper_link}>{t('learn_more_about')} {t(label)?.toLowerCase()} </span>}
            {showDivider && <Divider rootStyles={dividerStyles} /> }
        </>
    )
}
Header.defaultProps = { showDivider: true };

export default Header;