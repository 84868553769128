import React from 'react';

const Timer = () => {
  return (
    <svg
      width="148"
      height="58"
      viewBox="0 0 148 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="44.5" y="20.5" width="87" height="17" rx="4.5" fill="#FCFDFF" />
      <rect
        x="44.5"
        y="20.5"
        width="87"
        height="17"
        rx="4.5"
        stroke="#9EA7B6"
      />
      <rect x="62" y="24" width="10" height="10" rx="2" fill="#DADEE6" />
      <rect x="76" y="24" width="10" height="10" rx="2" fill="#DADEE6" />
      <rect x="90" y="24" width="10" height="10" rx="2" fill="#DADEE6" />
      <rect x="104" y="24" width="10" height="10" rx="2" fill="#DADEE6" />
      <g clipPath="url(#clip0_10452_9151)">
        <path
          d="M50.4375 29C50.4375 33.0943 48.8111 37.0209 45.916 39.916C43.0209 42.8111 39.0943 44.4375 35 44.4375C30.9057 44.4375 26.9791 42.8111 24.084 39.916C21.1889 37.0209 19.5625 33.0943 19.5625 29C19.5625 24.9057 21.1889 20.9791 24.084 18.084C26.9791 15.1889 30.9057 13.5625 35 13.5625C39.0943 13.5625 43.0209 15.1889 45.916 18.084C48.8111 20.9791 50.4375 24.9057 50.4375 29ZM33.2188 18.9062V29C33.2188 29.5937 33.5156 30.1504 34.0129 30.4844L41.1379 35.2344C41.9543 35.7836 43.0602 35.5609 43.6094 34.7371C44.1586 33.9133 43.9359 32.8148 43.1121 32.2656L36.7812 28.05V18.9062C36.7812 17.9191 35.9871 17.125 35 17.125C34.0129 17.125 33.2188 17.9191 33.2188 18.9062Z"
          fill="white"
        />
        <path
          d="M50.4375 28.9999C50.4375 33.0942 48.8111 37.0207 45.916 39.9158C43.0209 42.8109 39.0943 44.4374 35 44.4374C30.9057 44.4374 26.9791 42.8109 24.084 39.9158C21.1889 37.0207 19.5625 33.0942 19.5625 28.9999C19.5625 24.9056 21.1889 20.979 24.084 18.0839C26.9791 15.1888 30.9057 13.5624 35 13.5624C39.0943 13.5624 43.0209 15.1888 45.916 18.0839C48.8111 20.979 50.4375 24.9056 50.4375 28.9999ZM16 28.9999C16 34.039 18.0018 38.8717 21.565 42.4349C25.1282 45.9981 29.9609 47.9999 35 47.9999C40.0391 47.9999 44.8718 45.9981 48.435 42.4349C51.9982 38.8717 54 34.039 54 28.9999C54 23.9608 51.9982 19.128 48.435 15.5648C44.8718 12.0017 40.0391 9.99988 35 9.99988C29.9609 9.99988 25.1282 12.0017 21.565 15.5648C18.0018 19.128 16 23.9608 16 28.9999ZM33.2188 18.9061V28.9999C33.2188 29.5936 33.5156 30.1503 34.0129 30.4843L41.1379 35.2343C41.9543 35.7835 43.0602 35.5608 43.6094 34.737C44.1586 33.9132 43.9359 32.8147 43.1121 32.2655L36.7812 28.0499V18.9061C36.7812 17.919 35.9871 17.1249 35 17.1249C34.0129 17.1249 33.2188 17.919 33.2188 18.9061Z"
          fill="#9EA7B6"
        />
      </g>
      <defs>
        <clipPath id="clip0_10452_9151">
          <rect
            width="38"
            height="38"
            fill="white"
            transform="translate(16 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Timer;
